import { Station } from './../services/servicer/models/station';
import { UserDoStationCodesV2ApiResponse } from '../../providers/servicer/models/user-do-station-codes-v2-api-response';
import { DoStationCodes, StationGroup } from '../services/servicer/models/do-station-codes';
import { AnotherDestData } from '../../providers/servicer/models/anotherdestData';
import { StationForSearch } from '../../app/services/servicer/models/stationForSearch';
//import { Events } from 'ionic-angular';
import L from "leaflet";
//import { Const } from '../../providers/const';
//import { isArray } from 'ionic-angular/umd/util/util';

export class MapperUtil {
  /**
   * UserPuStationCodesV2ApiResponseデータをDoStationCodesに変換する.
   * @param data UserDoStationCodesV2ApiResponseデータ
   * @param stations ストップポイント一覧
   * @returns DoStationCodesデータ
   */

  static stationButtonCallback:(code:string)=>void = (code)=>{};


  static mapperUserPuStationCodesV2ApiResponseToDoStationCodes(data: UserDoStationCodesV2ApiResponse, stations: Station[]): DoStationCodes{
    const ret = {
      mainStations: [],
      stationGroups: []
    } as DoStationCodes;

    data.mainStationCodes.forEach((code)=>{
      const station = stations.find((station)=>station.code === code);
      if( station ){
        ret.mainStations.push(station);
      }
    });

    data.stationGroups.forEach((group)=>{
      const stationGroup = {
        name: group.name,
        lat: group.lat,
        lon: group.lon,
        zoom: group.zoom,
        doStations: []
      } as StationGroup;

      group.doStationCodes.forEach((code)=>{
        const station = stations.find((station)=>station.code === code);
        if( station ){
          stationGroup.doStations.push(station);
        }
      });
      
      ret.stationGroups.push(stationGroup);

    });

    
    return ret;
  }

  static mapperInitializeData(destdata: AnotherDestData): void{
    if(typeof(destdata.stationIconMap)==='undefined'){
      destdata.stationIconMap = new Map();
    }
    destdata.selectedStationIcon = null;
    destdata.searchType="";
    destdata.selectedSpotName="";
    destdata.drawingStationsState = {
      stations: [],
      pudo: ''
    }

  }

  static mapperIDestroyData(destdata: AnotherDestData): void{
    if(destdata.mapDisplayClass !== "load"){
      destdata.mapDisplayClass = "hide";
    }
  }


  static mapperSetStation(destdata: AnotherDestData, station:StationForSearch, _zoom:number,selected:boolean=true): void{
    destdata.myMap.alignCenter(station.lat,station.lon,{zoom:_zoom});
    this.mapperDrawStationIcon(destdata,[station], 'do', selected);
  }



  static mapperClearAllStationIcons(destdata: AnotherDestData): void{
    destdata.stationIconMap.forEach((stationIcon) => {
      destdata.myMap.clear(stationIcon);
    });

    destdata.stationIconMap.clear();
    destdata.selectedStationIcon = null;
  }

  static mapperClearView(destdata: AnotherDestData) {
    //this.myMap.clear(this.userIcon);
    //this.clearAllVehicleIcons();
    this.mapperClearAllStationIcons(destdata);
  }


  static _DrawStationIcon(destdata: AnotherDestData,station:Station, selected?:boolean){
    let className = '';

    if(station.type == 9){
      className = 'simple-icon-target disabled';
    }
    else if(station.type == 2 || station.type == 3 || station.type == 4) {
      className = 'simple-icon-target';
    }

    if(destdata.myMap.getZoom() < 17){
      className += className === ''?'simple' :' simple';
    }
    
    if(station.code === 'NH0101' //道の駅なみえ
      || station.code === 'NH0103' //JR浪江駅
      || station.code === 'NH0104' //浪江郵便局
      || station.code === 'NH0105' //イオン浪江店
      || station.code === 'NH0106' //浪江町役場
      || station.code === 'NH0113' //スポーツセンター
      || station.code === 'NH0114' //新町ふれあい広場
      ){
        destdata.drawingStationsState.stations.push(station);
        destdata.stationIconMap.set(
          station.code,
          destdata.myMap.stationMainIconBuilder(station.lat, station.lon, station.name, () => {
            /*
            this.events.publish(Const.EVENT_CHANGE_START_SCREEN_VIEW_TYPE, 
              { stationCode: station.code });
              this.selectStationIcon(station.code,destdata.drawingStationsState.pudo);
              */
            },'../assets/img/map/station/map-station-icon-'+ station.code + '.sw.cache.png').draw());
      }else{
        destdata.stationIconMap.set(
          station.code,
          destdata.myMap.stationIconBuilder(station.lat, station.lon, station.name, () => {
            /*
            this.events.publish(Const.EVENT_CHANGE_START_SCREEN_VIEW_TYPE, 
              { stationCode: stations.code });
              this.selectStationIcon(stations.code,pudo);
              */
          })
          .class(className)
          .draw());
      }

      if(selected){
        this.mapperSelectStationIcon(destdata,station.code, destdata.drawingStationsState.pudo);
      }
  }


  static mapperDrawStationIcon(destdata: AnotherDestData,stations:any, pudo?:string, selected?:boolean){
      destdata.drawingStationsState.pudo = pudo;
      let className = '';
      destdata.drawingStationsState.stations = stations;
      stations.forEach((station) => {
 
        if(station.type == 9){
          className = 'simple-icon-target disabled';
        }
        else if(station.type == 2 || station.type == 3 || station.type == 4) {
          className = 'simple-icon-target';
        }
    
        if(destdata.myMap.getZoom() < 17){
          className += className === ''?'simple' :' simple';
        }
        
        if(station.code === 'NH0101' //道の駅なみえ
          || station.code === 'NH0103' //JR浪江駅
          || station.code === 'NH0104' //浪江郵便局
          || station.code === 'NH0105' //イオン浪江店
          || station.code === 'NH0106' //浪江町役場
          || station.code === 'NH0113' //スポーツセンター
          || station.code === 'NH0114' //新町ふれあい広場
          ){
            destdata.drawingStationsState.stations.push(station);
            destdata.stationIconMap.set(
              station.code,
              destdata.myMap.stationMainIconBuilder(station.lat, station.lon, station.name, () => {
                /*
                this.events.publish(Const.EVENT_CHANGE_START_SCREEN_VIEW_TYPE, 
                  { stationCode: station.code });
                  this.selectStationIcon(station.code,destdata.drawingStationsState.pudo);
                  */
                  this.stationButtonCallback(station.code);
                },'../assets/img/map/station/map-station-icon-'+ station.code + '.sw.cache.png').draw());
          }else{
            destdata.stationIconMap.set(
              station.code,
              destdata.myMap.stationIconBuilder(station.lat, station.lon, station.name, () => {
                /*
                this.events.publish(Const.EVENT_CHANGE_START_SCREEN_VIEW_TYPE, 
                  { stationCode: stations.code });
                  this.selectStationIcon(stations.code,pudo);
                  */
                  this.stationButtonCallback(station.code);
              })
              .class(className)
              .draw());
          }
    
          if(selected){
            this.mapperSelectStationIcon(destdata, station.code, destdata.drawingStationsState.pudo);
          }
    
      });
  }


  static  mapperSelectStationIcon(destdata: AnotherDestData, stationCode:string, pudo:string){
    if(destdata.selectedStationIcon != null){
      
      L.DomUtil.removeClass(destdata.selectedStationIcon._icon, 'selected');
      L.DomUtil.removeClass(destdata.selectedStationIcon._icon, 'pink');
      L.DomUtil.removeClass(destdata.selectedStationIcon._icon, 'blue');
      
    }
    destdata.selectedStationIcon = destdata.stationIconMap.get(stationCode);
    L.DomUtil.addClass(destdata.selectedStationIcon._icon, 'selected');
    if(pudo === 'pu'){
      L.DomUtil.addClass(destdata.selectedStationIcon._icon, 'pink');
    }
    else if(pudo === 'do'){
      L.DomUtil.addClass(destdata.selectedStationIcon._icon, 'blue');
    }
  }


  static mapperGetStationForSearchFromCode(destdata: AnotherDestData,code:string): StationForSearch{
    return destdata.csvLoader.stationsForSearch.find(spot => spot.code === code);
  }

}